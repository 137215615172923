<template>
  <div class="costumes-page">
    <!-- Hero Section -->
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-size-1-touch">Folk Costume Manufacturers</h1>
        </div>
      </div>
    </section>

    <!-- Main Content Section -->
    <section class="section">
      <div class="container">
        <!-- Responsive Table -->
        <div class="table-container">
          <table class="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Regions Specialized</th>
                <th>Main Contact</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Facebook</th>
                <th>Website</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="costumeCompany in costumeCompanies" :key="costumeCompany.id">
                <td>{{ costumeCompany.companyName || costumeCompany.name }}</td>
                <td>{{ costumeCompany.regionsSpecialized }}</td>
                <td>{{ costumeCompany.mainContact }}</td>
                <td>{{ costumeCompany.phoneNumber }}</td>
                <td>{{ costumeCompany.email }}</td>
                <td v-if="costumeCompany.facebook">
                  <a target="_blank" rel="noopener noreferrer" :href="costumeCompany.facebook">
                    <img src="../img/fb.png" alt="Facebook" class="social-icon" />
                  </a>
                </td>
                <td v-else>—</td>
                <td v-if="costumeCompany.website">
                  <a target="_blank" rel="noopener noreferrer" :href="costumeCompany.website">
                    <img src="../img/web.png" alt="Website" class="social-icon" />
                  </a>
                </td>
                <td v-else>—</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CostumeService from "../services/costumeService";

export default {
  name: "Costumes",
  data() {
    return {
      costumeCompanies: []
    };
  },
  async mounted() {
    this.costumeCompanies = await CostumeService.getAll();
  }
};
</script>

<style lang="scss" scoped>
/* Hero Section */
.hero-body {
  background: linear-gradient(to right, #93291e, #ed213a);
}

.costumes-page {
  padding-bottom: 30px;
}

/* Responsive Table Styling */
.table-container {
  overflow-x: auto; /* Ensures table scrolls on smaller screens */
}

.table {
  margin-top: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

th, td {
  padding: 12px 15px;
  text-align: left;
}

th {
  background-color: #f5f5f5;
}

td {
  vertical-align: middle;
}

/* Social Icons */
.social-icon {
  max-height: 25px;
  transition: transform 0.2s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.1);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .hero-body {
    padding: 2rem 1.5rem;
  }

  table {
    font-size: 0.85rem;
  }

  th, td {
    padding: 10px;
  }
}
</style>
