import { db } from "../firebase";

class CostumeService {
  // Fetch all costume companies, sort by company name
  async getAll() {
    try {
      const snapshot = await db.collection("costumeCompanies").get();
      const documents = snapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...this.transformCostumeData(data),
        };
      });
      return documents.sort((a, b) =>
        a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase())
      );
    } catch (error) {
      console.error("Error fetching costumes:", error);
      return [];
    }
  }

  // Fetch a single costume by ID
  async get(id) {
    try {
      const doc = await db.collection("costumeCompanies").doc(id).get();
      if (!doc.exists) {
        throw new Error(`No costume found with ID: ${id}`);
      }
      return { id: doc.id, ...doc.data() };
    } catch (error) {
      console.error(`Error fetching costume with ID ${id}:`, error);
      return null;
    }
  }

  // Create a new costume entry
  async create(costume) {
    try {
      const docRef = await db.collection("costumeCompanies").add(costume);
      console.log(`Document written with ID: ${docRef.id}`);
      return docRef.id;
    } catch (error) {
      console.error("Error adding document:", error);
      throw error; // Re-throwing to handle errors in calling code
    }
  }

  // Update an existing costume by ID
  async update(id, value) {
    try {
      await db.collection("costumeCompanies").doc(id).update(value);
      console.log(`Document updated with ID: ${id}`);
      return id;
    } catch (error) {
      console.error(`Error updating document with ID ${id}:`, error);
      throw error;
    }
  }

  // Delete a costume entry by ID
  async delete(id) {
    try {
      await db.collection("costumeCompanies").doc(id).delete();
      console.log(`Document deleted with ID: ${id}`);
      return id;
    } catch (error) {
      console.error(`Error deleting document with ID ${id}:`, error);
      throw error;
    }
  }

  // Helper function to transform costume data
  transformCostumeData(data) {
    if (data.hasOwnProperty('name')) {
      return {
        companyName: data.name,
        ...data,
      };
    }
    return data;
  }
}

export default new CostumeService();
